import React from 'react'

import { Section, Button } from './style'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { widths } from 'src/styles/breakpoints'
import ImageWebp from 'src/components/ImageWebp'
import useWidth from 'src/hooks/window/useWidth'

type BenefitsType = {
    setIsOpen: Function;
    isMobile: boolean;
    oneLink: string;
  }

const DollarCreditCard = ({ setIsOpen, isMobile, oneLink }: BenefitsType) => {
    const [ sendDatalayerEvent ] = useDataLayer()
    const width = useWidth(300)
    return (
      <Section className='py-5 d-flex align-items-center'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-md-6 col-xl-5'>
              {
                   width < widths.md && (
                     <ImageWebp
                       pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/ga-global-mobile/image.webp'
                       altDescription=''
                       arrayNumbers={[ 334 ]}
                       arrayNumbersHeight={[ 274 ]}
                     />
                   )
                }
              <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fw-600 text-white mb-4 mb-md-4 w-100 w-md-90 w-lg-100 w-xl-90 mt-4'>
                Cartão de Crédito em Dólar Inter
              </h2>
              <p className='fs-14 lh-20 fs-md-16 lh-md-20 fs-lg-18 lh-lg-21 fs-xl-20 lh-xl-25 text-white fw-400 mb-4'>
                A facilidade das compras no crédito que você já conhece no Brasil, agora no mundo todo! Aproveite a exclusividade de ter o Cartão de Crédito Global.
              </p>
              {
              isMobile ? (
                <a
                  href={oneLink}
                  target='_blank'
                  rel='noreferrer'
                  title='Abrir Global Account'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_03',
                      section_name: 'Conta em dólar para usar onde estiver',
                      element_action: 'click button',
                      element_name: 'Abrir Global Account',
                      redirect_url: oneLink,
                    })
                  }}
                >
                  Abrir Global Account
                </a>
              ) : (
                <Button
                  onClick={() => {
                  setIsOpen(true)
                  sendDatalayerEvent({
                    section: 'dobra_03',
                    section_name: 'Conta em dólar para usar onde estiver',
                    element_action: 'click button',
                    element_name: 'Abrir Global Account',
                    })
                  }}
                >
                  Abrir Global Account
                </Button>
              )
            }
            </div>
          </div>
        </div>
      </Section>
    )
}

export default DollarCreditCard
