import React from 'react'

import ImageWebp from 'src/components/ImageWebp'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type BenefitsType = {
  setIsOpen: Function;
  isMobile: boolean;
  oneLink: string;
}

const BuyDollars = ({ setIsOpen, isMobile, oneLink }: BenefitsType) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <section id='dollar-account' className='py-5 d-flex align-items-center'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-xl-5 mb-4 mb-md-0 order-first order-md-last'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/ga-dobra04-nova/image.webp'
              altDescription='Super App Inter'
              arrayNumbers={[ 312, 336, 400, 456, 501 ]}
            />
          </div>
          <div className='col-12 col-md-6 col-xl-5'>
            <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 mb-4 mb-md-3'>
              <span className='d-lg-block'>Compre dólares com </span>pontos do cartão!
            </h2>
            <p className='fs-14 lh-17 fs-md-16 lh-md-19 fs-lg-18 lh-lg-22 text-grayscale--400'>
              Ao usar seu cartão de crédito você acumula pontos Inter Loop que podem ser trocados por dólares, carregados automaticamente na sua conta global!
            </p>
            {
              isMobile ? (
                <a
                  href={oneLink}
                  target='_blank'
                  className='btn btn-orange--extra btn--lg rounded-2 fs-14 text-none mw-100'
                  rel='noreferrer'
                  title='Abrir Global Account'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_03',
                      section_name: 'Conta em dólar para usar onde estiver',
                      element_action: 'click button',
                      element_name: 'Abrir Global Account',
                      redirect_url: oneLink,
                    })
                  }}
                >
                  Abrir Global Account
                </a>
              ) : (
                <button
                  className='btn btn-orange--extra btn--lg rounded-2 fs-14 text-none mw-100'
                  onClick={() => {
                    setIsOpen(true)
                    sendDatalayerEvent({
                      section: 'dobra_03',
                      section_name: 'Conta em dólar para usar onde estiver',
                      element_action: 'click button',
                      element_name: 'Abrir Global Account',
                    })
                  }}
                >
                  Abrir Global Account
                </button>
              )
            }
          </div>
        </div>
      </div>
    </section>
  )
}

export default BuyDollars
